.image-overlay-card {
  color: #fff;
  position: relative;
  height: 100%;
  .overlay-image {
    height: 100%;
    width: 100%;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      // z-index: -1;
      position: relative;
    }
  }
}

