@import 'tailwindcss/screens';

:root {
  --nocols: 12;
  --gutter: 12px;
  --outermargin: 24px;
  --colwidth: calc(100% / var(--nocols));

  @screen sm {
    --gutter: 16px;
    --outermargin: 32px;
  }
  @screen md {
    --gutter: 16px;
    --outermargin: 48px;
  }
  @screen xm {
    --gutter: 16px;
    --outermargin: 64px;
  }
  @screen lg {
    --gutter: 16px;
    --outermargin: 64px;
  }
  @screen xl {
    --gutter: 24px;
    --outermargin: 72px;
  }
  @screen xxl {
    --gutter: 24px;
    --outermargin: 120px;
  }
}

@screen md {
  .w-flow {
    width: calc(100% + var(--outermargin));
    &.flow-left {
      margin-left: calc(var(--outermargin) * -1);
    }
    &.flow-right {
      margin-right: calc(var(--outermargin) * -1);
    }
  }
}

@screen xm {
  .navigation-grid {
    margin-right: calc(var(--outermargin) - var(--gutter) / 2);
    margin-left: calc(var(--outermargin) - var(--gutter) / 2);
    padding-right: calc(var(--gutter) / 2);
    padding-left: calc(var(--gutter) / 2);
    max-width: calc(100% - (var(--outermargin) * 2) + var(--gutter));
  }
}
@media (max-width: 1440px) and (min-width: 1280px) {
  .navigation-grid {
    margin-right: 0; // 24 less gutter
    margin-left: 12px; // 24 less gutter
    // max-width: calc(100% - 48 + var(--gutter));
    max-width: calc(100% - 12px);
    padding-right: 0;
  }
}

[data-cols],
.container-grid {
  margin-right: calc(var(--outermargin) - var(--gutter) / 2);
  margin-left: calc(var(--outermargin) - var(--gutter) / 2);
  padding-right: calc(var(--gutter) / 2);
  padding-left: calc(var(--gutter) / 2);
  max-width: calc(100vw - (var(--outermargin) * 2) + var(--gutter));
  .row {
    width: calc(100% + var(--gutter));
    display: flex;
    flex-wrap: wrap;
    margin-right: calc(var(--gutter) / 2 * -1);
    margin-left: calc(var(--gutter) / 2 * -1);
  }
  [data-cols]:not(.is-fixed) {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }
}

.container-break-right {
  margin-left: calc(var(--outermargin) - var(--gutter));
  padding-left: calc(var(--gutter) / 2);
}

.row {
  // xm,lg,xl, xxl
  [class*='span-'],
  [class*='xs-span-'],
  [class*='sm-span-'],
  [class*='md-span-'],
  [class*='xm-span'],
  [class*='lg-span'],
  [class*='xl-span'],
  [class*='xxl-span-'] {
    padding-right: calc(var(--gutter) / 2);
    padding-left: calc(var(--gutter) / 2);
  }
}

[data-cols='1'] {
  --nocols: 1;
  --colwidth: calc(100% / var(--nocols));
}

[data-cols='2'] {
  --nocols: 2;
  --colwidth: calc(100% / var(--nocols));
}

[data-cols='3'] {
  --nocols: 3;
  --colwidth: calc(100% / var(--nocols));
}

[data-cols='4'] {
  --nocols: 4;
  --colwidth: calc(100% / var(--nocols));
}

[data-cols='5'] {
  --nocols: 5;
  --colwidth: calc(100% / var(--nocols));
}

[data-cols='6'] {
  --nocols: 6;
  --colwidth: calc(100% / var(--nocols));
}

[data-cols='7'] {
  --nocols: 7;
  --colwidth: calc(100% / var(--nocols));
}

[data-cols='8'] {
  --nocols: 8;
  --colwidth: calc(100% / var(--nocols));
}

[data-cols='9'] {
  --nocols: 9;
  --colwidth: calc(100% / var(--nocols));
}

[data-cols='10'] {
  --nocols: 10;
  --colwidth: calc(100% / var(--nocols));
}

[data-cols='11'] {
  --nocols: 11;
  --colwidth: calc(100% / var(--nocols));
}

[data-cols='12'] {
  --nocols: 12;
  --colwidth: calc(100% / var(--nocols));
}

[data-cols='13'] {
  --nocols: 11;
  --colwidth: calc(100% / var(--nocols));
}

[data-cols='14'] {
  --nocols: 14;
  --colwidth: calc(100% / var(--nocols));
}

.span-1 {
  width: calc(var(--colwidth));
}
.span-1.gutter {
  width: calc((var(--colwidth)));
  margin-right: var(--gutter);
}
.span-2 {
  width: calc(var(--colwidth) * 2);
}
.span-2.gutter {
  width: calc((var(--colwidth) * 2) - var(--gutter));
  margin-right: var(--gutter);
}
.span-3 {
  width: calc(var(--colwidth) * 3);
}
.span-3.gutter {
  width: calc((var(--colwidth) * 3) - var(--gutter));
  margin-right: var(--gutter);
}
.span-4 {
  width: calc(var(--colwidth) * 4);
}
.span-4.gutter {
  width: calc((var(--colwidth) * 4) - var(--gutter));
  margin-right: var(--gutter);
}
.span-5 {
  width: calc(var(--colwidth) * 5);
}
.span-5.gutter {
  width: calc((var(--colwidth) * 5) - var(--gutter));
  margin-right: var(--gutter);
}
.span-6 {
  width: calc(var(--colwidth) * 6);
}
.span-6.gutter {
  width: calc((var(--colwidth) * 6) - var(--gutter));
  margin-right: var(--gutter);
}
.span-7 {
  width: calc(var(--colwidth) * 7);
}
.span-7.gutter {
  width: calc((var(--colwidth) * 7) - var(--gutter));
  margin-right: var(--gutter);
}
.span-8 {
  width: calc(var(--colwidth) * 8);
}
.span-8.gutter {
  width: calc((var(--colwidth) * 8) - var(--gutter));
  margin-right: var(--gutter);
}
.span-9 {
  width: calc(var(--colwidth) * 9);
}
.span-9.gutter {
  width: calc((var(--colwidth) * 9) - var(--gutter));
  margin-right: var(--gutter);
}
.span-10 {
  width: calc(var(--colwidth) * 10);
}
.span-10.gutter {
  width: calc((var(--colwidth) * 10) - var(--gutter));
  margin-right: var(--gutter);
}
.span-11 {
  width: calc(var(--colwidth) * 11);
}
.span-11.gutter {
  width: calc((var(--colwidth) * 11) - var(--gutter));
  margin-right: var(--gutter);
}
.span-12 {
  width: calc(var(--colwidth) * 12);
}
.span-12.gutter {
  width: calc((var(--colwidth) * 12) - var(--gutter));
  margin-right: var(--gutter);
}
.span-13 {
  width: calc(var(--colwidth) * 13);
}
.span-13.gutter {
  width: calc((var(--colwidth) * 13) - var(--gutter));
  margin-right: var(--gutter);
}
.span-14 {
  width: calc(var(--colwidth) * 14);
}
.span-14.gutter {
  width: calc((var(--colwidth) * 14) - var(--gutter));
  margin-right: var(--gutter);
}
.offset-0 {
  margin-left: 0;
}
.offset-1 {
  margin-left: var(--colwidth);
}
.offset-2 {
  margin-left: calc(var(--colwidth) * 2);
}
.offset-3 {
  margin-left: calc(var(--colwidth) * 3);
}
.offset-4 {
  margin-left: calc(var(--colwidth) * 4);
}
.offset-5 {
  margin-left: calc(var(--colwidth) * 5);
}
.offset-6 {
  margin-left: calc(var(--colwidth) * 6);
}
.offset-7 {
  margin-left: calc(var(--colwidth) * 7);
}
.offset-8 {
  margin-left: calc(var(--colwidth) * 8);
}
.offset-9 {
  margin-left: calc(var(--colwidth) * 9);
}
.offset-10 {
  margin-left: calc(var(--colwidth) * 10);
}
.offset-11 {
  margin-left: calc(var(--colwidth) * 11);
}
.offset-12 {
  margin-left: calc(var(--colwidth) * 12);
}
.offset-13 {
  margin-left: calc(var(--colwidth) * 13);
}

@screen xs {
  .xs-span-1 {
    width: var(--colwidth);
  }
  .xs-span-1.xs-gutter {
    width: calc((var(--colwidth)));
    margin-right: var(--gutter);
  }
  .xs-span-2 {
    width: calc(var(--colwidth) * 2);
  }
  .xs-span-2.xs-gutter {
    width: calc((var(--colwidth) * 2) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xs-span-3 {
    width: calc(var(--colwidth) * 3);
  }
  .xs-span-3.xs-gutter {
    width: calc((var(--colwidth) * 3) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xs-span-4 {
    width: calc(var(--colwidth) * 4);
  }
  .xs-span-4.xs-gutter {
    width: calc((var(--colwidth) * 4) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xs-span-5 {
    width: calc(var(--colwidth) * 5);
  }
  .xs-span-5.xs-gutter {
    width: calc((var(--colwidth) * 5) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xs-span-6 {
    width: calc(var(--colwidth) * 6);
  }
  .xs-span-6.xs-gutter {
    width: calc((var(--colwidth) * 6) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xs-span-7 {
    width: calc(var(--colwidth) * 7);
  }
  .xs-span-7.xs-gutter {
    width: calc((var(--colwidth) * 7) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xs-span-8 {
    width: calc(var(--colwidth) * 8);
  }
  .xs-span-8.xs-gutter {
    width: calc((var(--colwidth) * 8) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xs-span-9 {
    width: calc(var(--colwidth) * 9);
  }
  .xs-span-9.xs-gutter {
    width: calc((var(--colwidth) * 9) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xs-span-10 {
    width: calc(var(--colwidth) * 10);
  }
  .xs-span-10.xs-gutter {
    width: calc((var(--colwidth) * 10) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xs-span-11 {
    width: calc(var(--colwidth) * 11);
  }
  .xs-span-11.xs-gutter {
    width: calc((var(--colwidth) * 11) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xs-span-12 {
    width: calc(var(--colwidth) * 12);
  }
  .xs-span-12.xs-gutter {
    width: calc((var(--colwidth) * 12) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xs-span-13 {
    width: calc(var(--colwidth) * 13);
  }
  .xs-span-13.xs-gutter {
    width: calc((var(--colwidth) * 13) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xs-span-14 {
    width: calc(var(--colwidth) * 14);
  }
  .xs-span-14.xs-gutter {
    width: calc((var(--colwidth) * 14) - var(--gutter));
    margin-right: var(--gutter);
  }

  .xs-offset-0 {
    margin-left: 0;
  }
  .xs-offset-1 {
    margin-left: var(--colwidth);
  }
  .xs-offset-2 {
    margin-left: calc(var(--colwidth) * 2);
  }
  .xs-offset-3 {
    margin-left: calc(var(--colwidth) * 3);
  }
  .xs-offset-4 {
    margin-left: calc(var(--colwidth) * 4);
  }
  .xs-offset-5 {
    margin-left: calc(var(--colwidth) * 5);
  }
  .xs-offset-6 {
    margin-left: calc(var(--colwidth) * 6);
  }
  .xs-offset-7 {
    margin-left: calc(var(--colwidth) * 7);
  }
  .xs-offset-8 {
    margin-left: calc(var(--colwidth) * 8);
  }
  .xs-offset-9 {
    margin-left: calc(var(--colwidth) * 9);
  }
  .xs-offset-10 {
    margin-left: calc(var(--colwidth) * 10);
  }
  .xs-offset-11 {
    margin-left: calc(var(--colwidth) * 11);
  }
  .xs-offset-12 {
    margin-left: calc(var(--colwidth) * 12);
  }
  .xs-offset-13 {
    margin-left: calc(var(--colwidth) * 13);
  }
}

@screen sm {
  .sm-span-1 {
    width: var(--colwidth);
  }
  .sm-span-1.sm-gutter {
    width: calc((var(--colwidth)));
    margin-right: var(--gutter);
  }
  .sm-span-2 {
    width: calc(var(--colwidth) * 2);
  }
  .sm-span-2.sm-gutter {
    width: calc((var(--colwidth) * 2) - var(--gutter));
    margin-right: var(--gutter);
  }
  .sm-span-3 {
    width: calc(var(--colwidth) * 3);
  }
  .sm-span-3.sm-gutter {
    width: calc((var(--colwidth) * 3) - var(--gutter));
    margin-right: var(--gutter);
  }
  .sm-span-4 {
    width: calc(var(--colwidth) * 4);
  }
  .sm-span-4.sm-gutter {
    width: calc((var(--colwidth) * 4) - var(--gutter));
    margin-right: var(--gutter);
  }
  .sm-span-5 {
    width: calc(var(--colwidth) * 5);
  }
  .sm-span-5.sm-gutter {
    width: calc((var(--colwidth) * 5) - var(--gutter));
    margin-right: var(--gutter);
  }
  .sm-span-6 {
    width: calc(var(--colwidth) * 6);
  }
  .sm-span-6.sm-gutter {
    width: calc((var(--colwidth) * 6) - var(--gutter));
    margin-right: var(--gutter);
  }
  .sm-span-7 {
    width: calc(var(--colwidth) * 7);
  }
  .sm-span-7.sm-gutter {
    width: calc((var(--colwidth) * 7) - var(--gutter));
    margin-right: var(--gutter);
  }
  .sm-span-8 {
    width: calc(var(--colwidth) * 8);
  }
  .sm-span-8.sm-gutter {
    width: calc((var(--colwidth) * 8) - var(--gutter));
    margin-right: var(--gutter);
  }
  .sm-span-9 {
    width: calc(var(--colwidth) * 9);
  }
  .sm-span-9.sm-gutter {
    width: calc((var(--colwidth) * 9) - var(--gutter));
    margin-right: var(--gutter);
  }
  .sm-span-10 {
    width: calc(var(--colwidth) * 10);
  }
  .sm-span-10.sm-gutter {
    width: calc((var(--colwidth) * 10) - var(--gutter));
    margin-right: var(--gutter);
  }
  .sm-span-11 {
    width: calc(var(--colwidth) * 11);
  }
  .sm-span-11.sm-gutter {
    width: calc((var(--colwidth) * 11) - var(--gutter));
    margin-right: var(--gutter);
  }
  .sm-span-12 {
    width: calc(var(--colwidth) * 12);
  }
  .sm-span-12.sm-gutter {
    width: calc((var(--colwidth) * 12) - var(--gutter));
    margin-right: var(--gutter);
  }
  .sm-span-13 {
    width: calc(var(--colwidth) * 13);
  }
  .sm-span-13.sm-gutter {
    width: calc((var(--colwidth) * 13) - var(--gutter));
    margin-right: var(--gutter);
  }
  .sm-span-14 {
    width: calc(var(--colwidth) * 14);
  }
  .sm-span-14.sm-gutter {
    width: calc((var(--colwidth) * 14) - var(--gutter));
    margin-right: var(--gutter);
  }

  .sm-offset-0 {
    margin-left: 0;
  }
  .sm-offset-1 {
    margin-left: var(--colwidth);
  }
  .sm-offset-2 {
    margin-left: calc(var(--colwidth) * 2);
  }
  .sm-offset-3 {
    margin-left: calc(var(--colwidth) * 3);
  }
  .sm-offset-4 {
    margin-left: calc(var(--colwidth) * 4);
  }
  .sm-offset-5 {
    margin-left: calc(var(--colwidth) * 5);
  }
  .sm-offset-6 {
    margin-left: calc(var(--colwidth) * 6);
  }
  .sm-offset-7 {
    margin-left: calc(var(--colwidth) * 7);
  }
  .sm-offset-8 {
    margin-left: calc(var(--colwidth) * 8);
  }
  .sm-offset-9 {
    margin-left: calc(var(--colwidth) * 9);
  }
  .sm-offset-10 {
    margin-left: calc(var(--colwidth) * 10);
  }
  .sm-offset-11 {
    margin-left: calc(var(--colwidth) * 11);
  }
  .sm-offset-12 {
    margin-left: calc(var(--colwidth) * 12);
  }
  .sm-offset-13 {
    margin-left: calc(var(--colwidth) * 13);
  }
}

@screen md {
  .md-span-1 {
    width: var(--colwidth);
  }
  .md-span-1.md-gutter {
    width: calc((var(--colwidth)));
    margin-right: var(--gutter);
  }
  .md-span-2 {
    width: calc(var(--colwidth) * 2);
  }
  .md-span-2.md-gutter {
    width: calc((var(--colwidth) * 2) - var(--gutter));
    margin-right: var(--gutter);
  }
  .md-span-3 {
    width: calc(var(--colwidth) * 3);
  }
  .md-span-3.md-gutter {
    width: calc((var(--colwidth) * 3) - var(--gutter));
    margin-right: var(--gutter);
  }
  .md-span-4 {
    width: calc(var(--colwidth) * 4);
  }
  .md-span-4.md-gutter {
    width: calc((var(--colwidth) * 4) - var(--gutter));
    margin-right: var(--gutter);
  }
  .md-span-5 {
    width: calc(var(--colwidth) * 5);
  }
  .md-span-5.md-gutter {
    width: calc((var(--colwidth) * 5) - var(--gutter));
    margin-right: var(--gutter);
  }
  .md-span-6 {
    width: calc(var(--colwidth) * 6);
  }
  .md-span-6.md-gutter {
    width: calc((var(--colwidth) * 6) - var(--gutter));
    margin-right: var(--gutter);
  }
  .md-span-7 {
    width: calc(var(--colwidth) * 7);
  }
  .md-span-7.md-gutter {
    width: calc((var(--colwidth) * 7) - var(--gutter));
    margin-right: var(--gutter);
  }
  .md-span-8 {
    width: calc(var(--colwidth) * 8);
  }
  .md-span-8.md-gutter {
    width: calc((var(--colwidth) * 8) - var(--gutter));
    margin-right: var(--gutter);
  }
  .md-span-9 {
    width: calc(var(--colwidth) * 9);
  }
  .md-span-9.md-gutter {
    width: calc((var(--colwidth) * 9) - var(--gutter));
    margin-right: var(--gutter);
  }
  .md-span-10 {
    width: calc(var(--colwidth) * 10);
  }
  .md-span-10.md-gutter {
    width: calc((var(--colwidth) * 10) - var(--gutter));
    margin-right: var(--gutter);
  }
  .md-span-11 {
    width: calc(var(--colwidth) * 11);
  }
  .md-span-11.md-gutter {
    width: calc((var(--colwidth) * 11) - var(--gutter));
    margin-right: var(--gutter);
  }
  .md-span-12 {
    width: calc(var(--colwidth) * 12);
  }
  .md-span-12.md-gutter {
    width: calc((var(--colwidth) * 12) - var(--gutter));
    margin-right: var(--gutter);
  }
  .md-span-13 {
    width: calc(var(--colwidth) * 13);
  }
  .md-span-13.md-gutter {
    width: calc((var(--colwidth) * 13) - var(--gutter));
    margin-right: var(--gutter);
  }
  .md-span-14 {
    width: calc(var(--colwidth) * 14);
  }
  .md-span-14.md-gutter {
    width: calc((var(--colwidth) * 14) - var(--gutter));
    margin-right: var(--gutter);
  }

  .md-offset-0 {
    margin-left: 0;
  }
  .md-offset-1 {
    margin-left: var(--colwidth);
  }
  .md-offset-2 {
    margin-left: calc(var(--colwidth) * 2);
  }
  .md-offset-3 {
    margin-left: calc(var(--colwidth) * 3);
  }
  .md-offset-4 {
    margin-left: calc(var(--colwidth) * 4);
  }
  .md-offset-5 {
    margin-left: calc(var(--colwidth) * 5);
  }
  .md-offset-6 {
    margin-left: calc(var(--colwidth) * 6);
  }
  .md-offset-7 {
    margin-left: calc(var(--colwidth) * 7);
  }
  .md-offset-8 {
    margin-left: calc(var(--colwidth) * 8);
  }
  .md-offset-9 {
    margin-left: calc(var(--colwidth) * 9);
  }
  .md-offset-10 {
    margin-left: calc(var(--colwidth) * 10);
  }
  .md-offset-11 {
    margin-left: calc(var(--colwidth) * 11);
  }
  .md-offset-12 {
    margin-left: calc(var(--colwidth) * 12);
  }
  .md-offset-13 {
    margin-left: calc(var(--colwidth) * 13);
  }
}

@screen xm {
  .xm-span-1 {
    width: var(--colwidth);
  }
  .xm-span-1.xm-gutter {
    width: calc((var(--colwidth)));
    margin-right: var(--gutter);
  }
  .xm-span-2 {
    width: calc(var(--colwidth) * 2);
  }
  .xm-span-2.xm-gutter {
    width: calc((var(--colwidth) * 2) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xm-span-3 {
    width: calc(var(--colwidth) * 3);
  }
  .xm-span-3.xm-gutter {
    width: calc((var(--colwidth) * 3) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xm-span-4 {
    width: calc(var(--colwidth) * 4);
  }
  .xm-span-4.xm-gutter {
    width: calc((var(--colwidth) * 4) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xm-span-5 {
    width: calc(var(--colwidth) * 5);
  }
  .xm-span-5.xm-gutter {
    width: calc((var(--colwidth) * 5) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xm-span-6 {
    width: calc(var(--colwidth) * 6);
  }
  .xm-span-6.xm-gutter {
    width: calc((var(--colwidth) * 6) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xm-span-7 {
    width: calc(var(--colwidth) * 7);
  }
  .xm-span-7.xm-gutter {
    width: calc((var(--colwidth) * 7) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xm-span-8 {
    width: calc(var(--colwidth) * 8);
  }
  .xm-span-8.xm-gutter {
    width: calc((var(--colwidth) * 8) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xm-span-9 {
    width: calc(var(--colwidth) * 9);
  }
  .xm-span-9.xm-gutter {
    width: calc((var(--colwidth) * 9) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xm-span-10 {
    width: calc(var(--colwidth) * 10);
  }
  .xm-span-10.xm-gutter {
    width: calc((var(--colwidth) * 10) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xm-span-11 {
    width: calc(var(--colwidth) * 11);
  }
  .xm-span-11.xm-gutter {
    width: calc((var(--colwidth) * 11) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xm-span-12 {
    width: calc(var(--colwidth) * 12);
  }
  .xm-span-12.xm-gutter {
    width: calc((var(--colwidth) * 12) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xm-span-13 {
    width: calc(var(--colwidth) * 13);
  }
  .xm-span-13.xm-gutter {
    width: calc((var(--colwidth) * 13) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xm-span-14 {
    width: calc(var(--colwidth) * 14);
  }
  .xm-span-14.xm-gutter {
    width: calc((var(--colwidth) * 14) - var(--gutter));
    margin-right: var(--gutter);
  }

  .xm-offset-0 {
    margin-left: 0;
  }
  .xm-offset-1 {
    margin-left: var(--colwidth);
  }
  .xm-offset-2 {
    margin-left: calc(var(--colwidth) * 2);
  }
  .xm-offset-3 {
    margin-left: calc(var(--colwidth) * 3);
  }
  .xm-offset-4 {
    margin-left: calc(var(--colwidth) * 4);
  }
  .xm-offset-5 {
    margin-left: calc(var(--colwidth) * 5);
  }
  .xm-offset-6 {
    margin-left: calc(var(--colwidth) * 6);
  }
  .xm-offset-7 {
    margin-left: calc(var(--colwidth) * 7);
  }
  .xm-offset-8 {
    margin-left: calc(var(--colwidth) * 8);
  }
  .xm-offset-9 {
    margin-left: calc(var(--colwidth) * 9);
  }
  .xm-offset-10 {
    margin-left: calc(var(--colwidth) * 10);
  }
  .xm-offset-11 {
    margin-left: calc(var(--colwidth) * 11);
  }
  .xm-offset-12 {
    margin-left: calc(var(--colwidth) * 12);
  }
  .xm-offset-13 {
    margin-left: calc(var(--colwidth) * 13);
  }
}

@screen lg {
  .lg-span-1 {
    width: var(--colwidth);
  }
  .lg-span-1.lg-gutter {
    width: calc((var(--colwidth)));
    margin-right: var(--gutter);
  }
  .lg-span-2 {
    width: calc(var(--colwidth) * 2);
  }
  .lg-span-2.lg-gutter {
    width: calc((var(--colwidth) * 2) - var(--gutter));
    margin-right: var(--gutter);
  }
  .lg-span-3 {
    width: calc(var(--colwidth) * 3);
  }
  .lg-span-3.lg-gutter {
    width: calc((var(--colwidth) * 3) - var(--gutter));
    margin-right: var(--gutter);
  }
  .lg-span-4 {
    width: calc(var(--colwidth) * 4);
  }
  .lg-span-4.lg-gutter {
    width: calc((var(--colwidth) * 4) - var(--gutter));
    margin-right: var(--gutter);
  }
  .lg-span-5 {
    width: calc(var(--colwidth) * 5);
  }
  .lg-span-5.lg-gutter {
    width: calc((var(--colwidth) * 5) - var(--gutter));
    margin-right: var(--gutter);
  }
  .lg-span-6 {
    width: calc(var(--colwidth) * 6);
  }
  .lg-span-6.lg-gutter {
    width: calc((var(--colwidth) * 6) - var(--gutter));
    margin-right: var(--gutter);
  }
  .lg-span-7 {
    width: calc(var(--colwidth) * 7);
  }
  .lg-span-7.lg-gutter {
    width: calc((var(--colwidth) * 7) - var(--gutter));
    margin-right: var(--gutter);
  }
  .lg-span-8 {
    width: calc(var(--colwidth) * 8);
  }
  .lg-span-8.lg-gutter {
    width: calc((var(--colwidth) * 8) - var(--gutter));
    margin-right: var(--gutter);
  }
  .lg-span-9 {
    width: calc(var(--colwidth) * 9);
  }
  .lg-span-9.lg-gutter {
    width: calc((var(--colwidth) * 9) - var(--gutter));
    margin-right: var(--gutter);
  }
  .lg-span-10 {
    width: calc(var(--colwidth) * 10);
  }
  .lg-span-10.lg-gutter {
    width: calc((var(--colwidth) * 10) - var(--gutter));
    margin-right: var(--gutter);
  }
  .lg-span-11 {
    width: calc(var(--colwidth) * 11);
  }
  .lg-span-11.lg-gutter {
    width: calc((var(--colwidth) * 11) - var(--gutter));
    margin-right: var(--gutter);
  }
  .lg-span-12 {
    width: calc(var(--colwidth) * 12);
  }
  .lg-span-12.lg-gutter {
    width: calc((var(--colwidth) * 12) - var(--gutter));
    margin-right: var(--gutter);
  }
  .lg-span-13 {
    width: calc(var(--colwidth) * 13);
  }
  .lg-span-13.lg-gutter {
    width: calc((var(--colwidth) * 13) - var(--gutter));
    margin-right: var(--gutter);
  }
  .lg-span-14 {
    width: calc(var(--colwidth) * 14);
  }
  .lg-span-14.lg-gutter {
    width: calc((var(--colwidth) * 14) - var(--gutter));
    margin-right: var(--gutter);
  }

  .lg-offset-0 {
    margin-left: 0;
  }
  .lg-offset-1 {
    margin-left: var(--colwidth);
  }
  .lg-offset-2 {
    margin-left: calc(var(--colwidth) * 2);
  }
  .lg-offset-3 {
    margin-left: calc(var(--colwidth) * 3);
  }
  .lg-offset-4 {
    margin-left: calc(var(--colwidth) * 4);
  }
  .lg-offset-5 {
    margin-left: calc(var(--colwidth) * 5);
  }
  .lg-offset-6 {
    margin-left: calc(var(--colwidth) * 6);
  }
  .lg-offset-7 {
    margin-left: calc(var(--colwidth) * 7);
  }
  .lg-offset-8 {
    margin-left: calc(var(--colwidth) * 8);
  }
  .lg-offset-9 {
    margin-left: calc(var(--colwidth) * 9);
  }
  .lg-offset-10 {
    margin-left: calc(var(--colwidth) * 10);
  }
  .lg-offset-11 {
    margin-left: calc(var(--colwidth) * 11);
  }
  .lg-offset-12 {
    margin-left: calc(var(--colwidth) * 12);
  }
  .lg-offset-13 {
    margin-left: calc(var(--colwidth) * 13);
  }
}

@screen xl {
  .xl-span-1 {
    width: var(--colwidth);
  }
  .xl-span-1.xl-gutter {
    width: calc((var(--colwidth)));
    margin-right: var(--gutter);
  }
  .xl-span-2 {
    width: calc(var(--colwidth) * 2);
  }
  .xl-span-2.xl-gutter {
    width: calc((var(--colwidth) * 2) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xl-span-3 {
    width: calc(var(--colwidth) * 3);
  }
  .xl-span-3.xl-gutter {
    width: calc((var(--colwidth) * 3) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xl-span-4 {
    width: calc(var(--colwidth) * 4);
  }
  .xl-span-4.xl-gutter {
    width: calc((var(--colwidth) * 4) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xl-span-5 {
    width: calc(var(--colwidth) * 5);
  }
  .xl-span-5.xl-gutter {
    width: calc((var(--colwidth) * 5) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xl-span-6 {
    width: calc(var(--colwidth) * 6);
  }
  .xl-span-6.xl-gutter {
    width: calc((var(--colwidth) * 6) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xl-span-7 {
    width: calc(var(--colwidth) * 7);
  }
  .xl-span-7.xl-gutter {
    width: calc((var(--colwidth) * 7) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xl-span-8 {
    width: calc(var(--colwidth) * 8);
  }
  .xl-span-8.xl-gutter {
    width: calc((var(--colwidth) * 8) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xl-span-9 {
    width: calc(var(--colwidth) * 9);
  }
  .xl-span-9.xl-gutter {
    width: calc((var(--colwidth) * 9) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xl-span-10 {
    width: calc(var(--colwidth) * 10);
  }
  .xl-span-10.xl-gutter {
    width: calc((var(--colwidth) * 10) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xl-span-11 {
    width: calc(var(--colwidth) * 11);
  }
  .xl-span-11.xl-gutter {
    width: calc((var(--colwidth) * 11) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xl-span-12 {
    width: calc(var(--colwidth) * 12);
  }
  .xl-span-12.xl-gutter {
    width: calc((var(--colwidth) * 12) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xl-span-13 {
    width: calc(var(--colwidth) * 13);
  }
  .xl-span-13.xl-gutter {
    width: calc((var(--colwidth) * 13) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xl-span-14 {
    width: calc(var(--colwidth) * 14);
  }
  .xl-span-14.xl-gutter {
    width: calc((var(--colwidth) * 14) - var(--gutter));
    margin-right: var(--gutter);
  }

  .xl-offset-0 {
    margin-left: 0;
  }
  .xl-offset-1 {
    margin-left: var(--colwidth);
  }
  .xl-offset-2 {
    margin-left: calc(var(--colwidth) * 2);
  }
  .xl-offset-3 {
    margin-left: calc(var(--colwidth) * 3);
  }
  .xl-offset-4 {
    margin-left: calc(var(--colwidth) * 4);
  }
  .xl-offset-5 {
    margin-left: calc(var(--colwidth) * 5);
  }
  .xl-offset-6 {
    margin-left: calc(var(--colwidth) * 6);
  }
  .xl-offset-7 {
    margin-left: calc(var(--colwidth) * 7);
  }
  .xl-offset-8 {
    margin-left: calc(var(--colwidth) * 8);
  }
  .xl-offset-9 {
    margin-left: calc(var(--colwidth) * 9);
  }
  .xl-offset-10 {
    margin-left: calc(var(--colwidth) * 10);
  }
  .xl-offset-11 {
    margin-left: calc(var(--colwidth) * 11);
  }
  .xl-offset-12 {
    margin-left: calc(var(--colwidth) * 12);
  }
  .xl-offset-13 {
    margin-left: calc(var(--colwidth) * 13);
  }
}

@screen xxl {
  .xxl-span-1 {
    width: var(--colwidth);
  }
  .xxl-span-1.xxl-gutter {
    width: calc((var(--colwidth)));
    margin-right: var(--gutter);
  }
  .xxl-span-2 {
    width: calc(var(--colwidth) * 2);
  }
  .xxl-span-2.xxl-gutter {
    width: calc((var(--colwidth) * 2) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xxl-span-3 {
    width: calc(var(--colwidth) * 3);
  }
  .xxl-span-3.xxl-gutter {
    width: calc((var(--colwidth) * 3) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xxl-span-4 {
    width: calc(var(--colwidth) * 4);
  }
  .xxl-span-4.xxl-gutter {
    width: calc((var(--colwidth) * 4) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xxl-span-5 {
    width: calc(var(--colwidth) * 5);
  }
  .xxl-span-5.xxl-gutter {
    width: calc((var(--colwidth) * 5) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xxl-span-6 {
    width: calc(var(--colwidth) * 6);
  }
  .xxl-span-6.xxl-gutter {
    width: calc((var(--colwidth) * 6) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xxl-span-7 {
    width: calc(var(--colwidth) * 7);
  }
  .xxl-span-7.xxl-gutter {
    width: calc((var(--colwidth) * 7) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xxl-span-8 {
    width: calc(var(--colwidth) * 8);
  }
  .xxl-span-8.xxl-gutter {
    width: calc((var(--colwidth) * 8) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xxl-span-9 {
    width: calc(var(--colwidth) * 9);
  }
  .xxl-span-9.xxl-gutter {
    width: calc((var(--colwidth) * 9) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xxl-span-10 {
    width: calc(var(--colwidth) * 10);
  }
  .xxl-span-10.xxl-gutter {
    width: calc((var(--colwidth) * 10) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xxl-span-11 {
    width: calc(var(--colwidth) * 11);
  }
  .xxl-span-11.xxl-gutter {
    width: calc((var(--colwidth) * 11) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xxl-span-12 {
    width: calc(var(--colwidth) * 12);
  }
  .xxl-span-12.xxl-gutter {
    width: calc((var(--colwidth) * 12) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xxl-span-13 {
    width: calc(var(--colwidth) * 13);
  }
  .xxl-span-13.xxl-gutter {
    width: calc((var(--colwidth) * 13) - var(--gutter));
    margin-right: var(--gutter);
  }
  .xxl-span-14 {
    width: calc(var(--colwidth) * 14);
  }
  .xxl-span-14.xxl-gutter {
    width: calc((var(--colwidth) * 14) - var(--gutter));
    margin-right: var(--gutter);
  }

  .xxl-offset-0 {
    margin-left: 0;
  }
  .xxl-offset-1 {
    margin-left: var(--colwidth);
  }
  .xxl-offset-2 {
    margin-left: calc(var(--colwidth) * 2);
  }
  .xxl-offset-3 {
    margin-left: calc(var(--colwidth) * 3);
  }
  .xxl-offset-4 {
    margin-left: calc(var(--colwidth) * 4);
  }
  .xxl-offset-5 {
    margin-left: calc(var(--colwidth) * 5);
  }
  .xxl-offset-6 {
    margin-left: calc(var(--colwidth) * 6);
  }
  .xxl-offset-7 {
    margin-left: calc(var(--colwidth) * 7);
  }
  .xxl-offset-8 {
    margin-left: calc(var(--colwidth) * 8);
  }
  .xxl-offset-9 {
    margin-left: calc(var(--colwidth) * 9);
  }
  .xxl-offset-10 {
    margin-left: calc(var(--colwidth) * 10);
  }
  .xxl-offset-11 {
    margin-left: calc(var(--colwidth) * 11);
  }
  .xxl-offset-12 {
    margin-left: calc(var(--colwidth) * 12);
  }
  .xxl-offset-13 {
    margin-left: calc(var(--colwidth) * 13);
  }
}

#grid {
  width: 100%;
  position: absolute;
  top: 0;
  margin-left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;

  & > div {
    float: left;
    width: calc(var(--colwidth) - var(--gutter));
    margin-right: calc(var(--gutter) / 2);
    margin-left: calc(var(--gutter) / 2);
    height: 100%;
    text-align: center;
  }
  & > div:nth-child(odd) {
    @apply bg-grid;
  }
  & > div:nth-child(even) {
    @apply bg-grid;
  }
}
