.colors {
  .color-circle {
    width: 100px;
    height: 100px;
    border-radius: 50px;
  }
}

.styleguide-scroll-bars {
  .scroll-bar {
    width: 50%;
  }

  .horizontal-scroll-parent {
    width: 450px;
    height: 450px;
    padding: 10px;

    &.scroll-invert {
      background: black;
      color: white;
    }
  }

  .horizontal-scroll-bar {
    width: 400px;
    overflow: hidden;
    height: 100px;
    overflow-x: scroll;
    white-space: nowrap;
    padding: 20px;
  }

  .vertical-scroll-bar {
    width: 400px;
    overflow: hidden;
    overflow-y: scroll;
    height: 400px;
    padding: 20px;

    &.scroll-invert {
      background: black;
      color: white;
    }
  }
}
