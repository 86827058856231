/*
  JSS Placeholder compatibility with Custom Grid
*/
.sc-jss-empty-placeholder {
    width: 100%;
}

/*
  Hides Sitecore editor markup,
  if you run the app in connected mode while the Sitecore cookies
  are set to edit mode.
*/
// .scChromeData, .scpm { display: none !important; }

/*
  Styles for default JSS error components
*/
// .sc-jss-editing-error,
// .sc-jss-placeholder-error {
//   padding: 1em;
//   background-color: lightyellow;
// }

/* 
  Style for default content block
*/
// .contentTitle {
//     font-size: 3.5rem;
//     font-weight: 300;
//     line-height: 1.2;
// }
