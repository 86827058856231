.transparent-right-40 {
  mask-image: gradient(linear, 90% top, 99% top, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
  -webkit-mask-image: -webkit-gradient(linear, 90% top, 99% top, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
}

.transparent-sides-fade {
  mask-image: linear-gradient(to right, transparent 0%, black 6%, black 60%, transparent 100%),
    linear-gradient(to left, transparent 0%, black 6%, black 60%, transparent 100%);
  -webkit-mask-image: linear-gradient(to right, transparent 0%, black 6%, black 94%, transparent 100%),
    linear-gradient(to left, transparent 0%, black 6%, black 94%, transparent 100%);
}

.transparent-fade {
  -webkit-mask-image: linear-gradient(to top, transparent 0%, black 6%, black 94%, transparent 100%);
  mask-image: linear-gradient(to bottom, transparent 0%, black 6%, black 94%, transparent 100%);
}

.transparent-bottom-profile {
  -webkit-mask-image: linear-gradient(to top, transparent 0%, black 6%, black 100%);
  mask-image: linear-gradient(to bottom, transparent 0%, black 6%, black 100%);
}

