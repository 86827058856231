html {
  margin: 0px;
  font-size: 16px;
}

// a[target='_blank']:after {
//   content: '\1F5D7'
// }

// a[target='_blank']:after {
//   content: '\1F5D7'
// }

// apply base theme colours to class
// .light {
//   background-color: #ffffff;
//   color: #000000;
// }
// .dark,
// .grey {
//   background-color: #181818;
//   color: white;
// }

//for rich text fields - global styling

.gradient-overlay {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
}

.gradient-overlay-white {
  background: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}

.opacity-overlay {
  background: rgba(0, 0, 0, 0.6);
}

.adf-scroll-bar-container {
  .adf-scroll-bar {
    @supports not selector(::-webkit-scrollbar) {
      scrollbar-width: thin;
      scrollbar-color: $tri-service-dark-grey $tri-service-light-grey;
    }

    .dark :not(.light) & {
      @supports not selector(::-webkit-scrollbar) {
        scrollbar-color: $tri-service-light-grey $tri-service-dark-grey;
      }
    }
  }

  /* width */
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: $tri-service-light-grey;

    .dark :not(.light) & {
      background-color: $tri-service-dark-grey;
    }
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $tri-service-dark-grey;
    border-radius: 2px;
    height: 15px;

    .dark :not(.light) & {
      background-color: $tri-service-light-grey;
    }
  }
}

@layer base {
  :root,
  .light {
    --focus-color: rgb(0, 0, 0); // tri-service-grey
    --focus-offset-color: rgb(255, 255, 255); // white
  }

  .dark {
    --focus-color: rgb(239, 239, 239); // tri-service-pale-grey
    --focus-offset-color: rgb(24, 24, 24); // off black
  }

  *:focus-visible {
    outline: none;
    outline-width: 0;
    @apply ring-[3px] ring-[--focus-color] ring-offset-4 ring-offset-[--focus-offset-color];
    // Force it to sit over everything
    z-index: 4000 !important;
  }
}

@layer utilities {
  .divider-y {
    @apply my-[var(--margin-y,0px)] block h-px border-b border-grey-light sm:my-[var(--margin-y-sm,var(--margin-y,0px))] md:my-[var(--margin-y-md,var(--margin-y-sm,var(--margin-y,0px)))] lg:my-[var(--margin-y-lg,var(--margin-y-md,var(--margin-y-sm,var(--margin-y,0px))))] xl:my-[var(--margin-y-xl,var(--margin-y-lg,var(--margin-y-md,var(--margin-y-sm,var(--margin-y,0px)))))] xxl:my-[var(--margin-y-xxl,var(--margin-y-xl,var(--margin-y-lg,var(--margin-y-md,var(--margin-y-sm,var(--margin-y,0px))))))];
  }
}
