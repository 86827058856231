.svg-animated {
  rect,
  circle,
  path,
  line,
  polygon {
    stroke-dasharray: 170;
    stroke-dashoffset: 0;
    transition: stroke-dashoffset 1.5s;
  }
}
.svg-static {
  rect,
  circle,
  path,
  line,
  polygon {
    stroke-dasharray: 170 !important;
    stroke-dashoffset: 170 !important;
    transition: stroke-dashoffset 1.5s;
  }
}

.svg-icon {
  path,
  rect,
  line,
  circle,
  polygon {
    stroke-width: 1;
  }
}
@screen xm {
  .svg-icon {
    path,
    rect,
    line,
    circle,
    polygon {
      stroke-width: 1.4;
    }
  }
}

.svg-clicked {
  cursor: pointer;
}

.svg-clicked .radio-selected {
  transition:
    transform 0.2s,
    opacity 0.2s;
}
.svg-clicked .radio-selected:active {
  transform: scale(0.8);
}
.svg-clicked .radio-selected path {
  opacity: 0;
  transform: scale(0.3);
  transform-origin: 50%;
  transition:
    transform 0.2s,
    opacity 0.3s;
}
.svg-clicked.clicked-active .radio-selected path {
  opacity: 1;
  transform: scale(1);
}

.svg-clicked .menu {
  transition: transform 0.2s;
}
.svg-clicked .menu path {
  transform-origin: 50%;
  transition: transform 0.2s;
}
.svg-clicked.clicked-active .menu path:nth-child(1) {
  transform: scale(0);
}
.svg-clicked.clicked-active .menu path:nth-child(2) {
  transform: rotate(45deg) translateY(25%);
}
.svg-clicked.clicked-active .menu path:nth-child(3) {
  transform: rotate(-45deg) translateY(-25%);
}

.svg-clicked .chevron-down path {
  transform-origin: 50%;
  transition: transform 0.3s;
}
.svg-clicked.clicked-active .chevron-down path {
  transform: rotate(180deg);
}

.svg-clicked.clicked-active.chevron-down path {
  transform: rotate(180deg);
}

.svg-clicked .add path {
  transform-origin: 50%;
  transition: transform 0.3s;
}
.svg-clicked.clicked-active .add path:nth-child(2) {
  transform: rotate(90deg);
}

.svg-clicked .checkbox {
  transition:
    transform 0.2s,
    fill 0.2s;
}
.svg-clicked .checkbox:active {
  transform: scale(0.8);
}
.svg-clicked .checkbox path:nth-child(1) {
  transform: fill 0.8s;
}
.svg-clicked.clicked-active .checkbox path:nth-child(1) {
  fill: #000;
}
.svg-clicked .checkbox path:nth-child(2) {
  transform-origin: 50%;
  stroke-dasharray: 170 !important;
  stroke-dashoffset: 170 !important;
  transition: stroke-dashoffset 0.4s;
  stroke: #fff;
}
.svg-clicked.clicked-active .checkbox path:nth-child(2) {
  stroke-dashoffset: 150 !important;
}

.svg-clicked .play path:nth-child(1) {
  transform: scale(0);
  transform-origin: 100% 50%;
  transition:
    transform 0.3s,
    opacity 0.3s;
  opacity: 0;
}
.svg-clicked.clicked-active .play path:nth-child(1) {
  transform: scale(1);
  opacity: 1 !important;
}

// SEARCH TO CLOSE
.svg-clicked .search {
  transition: transform 0.2s;
  &:active {
    transform: scale(0.8);
  }
  path {
    transform-origin: 50%;
    transition: transform 0.2s;
  }
  path:nth-child(1),
  path:nth-child(2) {
    opacity: 1;
    transform: scale(1);
  }
  path:nth-child(3),
  path:nth-child(4) {
    opacity: 0;
    transform: scale(0.3);
  }
}
.svg-clicked.clicked-active .search {
  path:nth-child(1),
  path:nth-child(2) {
    opacity: 0;
    transform: scale(0.3);
  }
  path:nth-child(3),
  path:nth-child(4) {
    opacity: 1 !important;
    transform: scale(1);
  }
}

//HOVER TRANSITIONS
.svg-hover {
  cursor: pointer;
}
//RIGHT DOUBLE HOVER
.svg-hover .chevron-right-double {
  polygon {
    transition:
      transform 0.3s,
      opacity 0.3s;
    cursor: pointer;
  }
  // .icon-hover-true & {
  //   polygon {
  //     transition-delay: 0.2s;
  //   }
  // }
  polygon:nth-child(1) {
    opacity: 0;
    transform: scale(0.3);
    transform-origin: 0 50%;
  }
  polygon:nth-child(3) {
    transform-origin: 100% 50%;
  }
  &:hover,
  .icon-hover-true &,
  .icon-hover:hover & {
    polygon:nth-child(1) {
      opacity: 1;
      transform: scale(1);
    }
    polygon:nth-child(2) {
      transform: translateX(7px);
    }
    polygon:nth-child(3) {
      opacity: 0;
      transform: scale(0.3);
    }
  }
}

.svg-loading .chevron-right-double {
  polygon:nth-child(1) {
    animation: loading-1 1000ms infinite;
    transform-origin: 0 50%;
  }
  polygon:nth-child(2) {
    animation: loading-2 1000ms infinite;
  }
  polygon:nth-child(3) {
    animation: loading-3 1000ms infinite;
    transform-origin: 100% 50%;
  }
}

@keyframes loading-1 {
  from {
    opacity: 0;
    transform: scale(0.3);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes loading-2 {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(7px);
  }
}
@keyframes loading-3 {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.3);
  }
}

//FILTER HOVER
.svg-hover .filter {
  g g {
    transition: transform 0.3s;
  }
  &:hover,
  .icon-hover-true & {
    g g:nth-child(3) {
      transform: translateY(-5px);
    }
    g g:nth-child(2) {
      transform: translateY(5px);
    }
    g g:nth-child(1) {
      transform: translateY(-5px);
    }
  }
}

//DELETE HOVER
.svg-hover .delete {
  g {
    transition: transform 0.3s;
  }
  &:hover,
  .icon-hover-true & {
    g {
      transform: rotate(-10deg) translateX(-1px);
    }
  }
}

//DOWNLOAD HOVER
.svg-hover .download {
  path:nth-child(2) {
    stroke-dasharray: 5;
    stroke-dashoffset: 0;
  }
  path:nth-child(4) {
    stroke-dasharray: 5;
    stroke-dashoffset: 10;
  }
  path:nth-child(3) {
    stroke-dasharray: 30;
    stroke-dashoffset: 20;
  }
  &:hover,
  .icon-hover-true & {
    path:nth-child(2) {
      animation: download2 0.7s;
    }
    path:nth-child(4) {
      animation: download4 0.7s;
    }
    path:nth-child(3) {
      animation: download3 0.4s;
    }
  }
}
@keyframes download3 {
  0% {
    stroke-dasharray: 20;
  }
  50% {
    stroke-dasharray: 20;
  }
  100% {
    stroke-dasharray: 30;
  }
}
@keyframes download4 {
  0% {
    stroke-dashoffset: 5;
  }
  50% {
    stroke-dashoffset: 5;
  }
  100% {
    stroke-dashoffset: 10;
  }
}
@keyframes download2 {
  0% {
    stroke-dashoffset: 5;
  }
  50% {
    stroke-dashoffset: 5;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

//UPLOAD HOVER
.svg-hover .upload {
  path:nth-child(2) {
    stroke-dasharray: 5;
    stroke-dashoffset: 0;
  }
  path:nth-child(4) {
    stroke-dasharray: 5;
    stroke-dashoffset: 10;
  }
  path:nth-child(3) {
    stroke-dasharray: 30;
    stroke-dashoffset: 20;
  }
  &:hover,
  .icon-hover-true & {
    path:nth-child(2) {
      animation: upload2 0.7s;
    }
    path:nth-child(4) {
      animation: upload4 0.7s;
    }
    path:nth-child(3) {
      animation: upload3 0.4s;
    }
  }
}
@keyframes upload3 {
  0% {
    stroke-dasharray: 20;
  }
  50% {
    stroke-dasharray: 20;
  }
  100% {
    stroke-dasharray: 30;
  }
}
@keyframes upload4 {
  0% {
    stroke-dashoffset: 5;
  }
  50% {
    stroke-dashoffset: 5;
  }
  100% {
    stroke-dashoffset: 10;
  }
}
@keyframes upload2 {
  0% {
    stroke-dashoffset: 5;
  }
  50% {
    stroke-dashoffset: 5;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

//ARROW EAST HOVER
.svg-hover .arrow-east {
  path:nth-child(1) {
    stroke-dasharray: 20;
    stroke-dashoffset: 0;
  }
  path:nth-child(3) {
    stroke-dasharray: 10;
    stroke-dashoffset: 20;
  }
  path:nth-child(2) {
    stroke-dasharray: 10;
    stroke-dashoffset: 20;
  }
  &:hover,
  .icon-hover-true & {
    path:nth-child(1) {
      animation: east1 0.4s;
    }
    path:nth-child(3) {
      animation: east3 0.7s;
    }
    path:nth-child(2) {
      animation: east2 0.7s;
    }
  }
}
@keyframes east1 {
  0% {
    stroke-dashoffset: 20;
  }
  20% {
    stroke-dashoffset: 20;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes east3 {
  0% {
    stroke-dashoffset: 10;
  }
  50% {
    stroke-dashoffset: 10;
  }
  100% {
    stroke-dashoffset: 20;
  }
}
@keyframes east2 {
  0% {
    stroke-dashoffset: 30;
  }
  50% {
    stroke-dashoffset: 30;
  }
  100% {
    stroke-dashoffset: 20;
  }
}

//DIRECTIONS HOVER
.svg-hover .directions {
  path:nth-child(2) {
    stroke-dasharray: 20;
    stroke-dashoffset: 40;
  }
  path:nth-child(3) {
    stroke-dasharray: 10;
    stroke-dashoffset: 20;
  }
  path:nth-child(4) {
    stroke-dasharray: 10;
    stroke-dashoffset: 27;
  }
  &:hover,
  .icon-hover-true & {
    path:nth-child(2) {
      animation: directions2 0.4s;
    }
    path:nth-child(3) {
      animation: directions3 0.7s;
    }
    path:nth-child(4) {
      animation: directions4 0.7s;
    }
  }
}
@keyframes directions2 {
  0% {
    stroke-dashoffset: 30;
  }
  20% {
    stroke-dashoffset: 30;
  }
  100% {
    stroke-dashoffset: 40;
  }
}
@keyframes directions3 {
  0% {
    stroke-dashoffset: 17;
  }
  50% {
    stroke-dashoffset: 17;
  }
  100% {
    stroke-dashoffset: 20;
  }
}
@keyframes directions4 {
  0% {
    stroke-dashoffset: 30;
  }
  50% {
    stroke-dashoffset: 30;
  }
  100% {
    stroke-dashoffset: 27;
  }
}

//REFRESH HOVER
.svg-hover .refresh {
  path {
    transition: transform 0.4s;
    transform-origin: 50%;
  }
  &:hover,
  .icon-hover-true & {
    path {
      transform: rotate(360deg);
    }
  }
}

//DIRECTIONS HOVER
.svg-hover .open-new {
  line:nth-child(2) {
    stroke-dasharray: 20;
    stroke-dashoffset: 0;
  }
  line:nth-child(3) {
    stroke-dasharray: 10;
    stroke-dashoffset: 20;
  }
  line:nth-child(4) {
    stroke-dasharray: 10;
    stroke-dashoffset: 22;
  }
  &:hover,
  .icon-hover-true & {
    line:nth-child(2) {
      animation: opennew2 0.4s;
    }
    line:nth-child(3) {
      animation: opennew3 0.7s;
    }
    line:nth-child(4) {
      animation: opennew4 0.7s;
    }
  }
}
@keyframes opennew2 {
  0% {
    stroke-dashoffset: 20;
  }
  20% {
    stroke-dashoffset: 20;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes opennew3 {
  0% {
    stroke-dashoffset: 32;
  }
  50% {
    stroke-dashoffset: 32;
  }
  100% {
    stroke-dashoffset: 22;
  }
}
@keyframes opennew4 {
  0% {
    stroke-dashoffset: 30;
  }
  50% {
    stroke-dashoffset: 30;
  }
  100% {
    stroke-dashoffset: 22;
  }
}

//LOCATION HOVER
.svg-hover .location {
  transition: transform 0.3s;
  path {
    transition: transform 0.3s;
    transform-origin: 50%;
  }
  &:hover,
  .icon-hover-true & {
    animation: locBounce 0.8s ease forwards;
    path:nth-child(1) {
      transform: scale(1.2);
    }
  }
}
@keyframes locBounce {
  0%,
  20%,
  60%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  90% {
    transform: translateY(-3px);
  }
}

//CHAT HOVER
.svg-hover .chat {
  path:nth-child(2),
  path:nth-child(3),
  path:nth-child(4) {
    stroke-dasharray: 20;
    stroke-dashoffset: 8;
  }
  &:hover,
  .icon-hover-true & {
    path:nth-child(2) {
      animation: chaton 0.8s ease 0.2s forwards;
    }
    path:nth-child(3) {
      animation: chaton 0.8s ease 0.1s forwards;
    }
    path:nth-child(4) {
      animation: chaton 0.8s ease 0s forwards;
    }
  }
}
@keyframes chaton {
  0% {
    stroke-dashoffset: 8;
  }
  50% {
    stroke-dashoffset: 20;
  }
  100% {
    stroke-dashoffset: 8;
  }
}

//SHORTLIST HOVER
.svg-hover .shortlist {
  path {
    transition: transform 0.6s;
  }
  &:hover,
  .icon-hover-true & {
    path:nth-child(2) {
      animation: shortlist 0.8s ease 0.2s forwards;
    }
    path:nth-child(3) {
      animation: shortlist 0.8s ease 0.1s forwards;
    }
    path:nth-child(4) {
      animation: shortlist 0.8s ease 0s forwards;
    }
  }
}
@keyframes shortlist {
  0% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(2px);
  }
  50% {
    transform: translateX(-4px);
  }
  75% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(0px);
  }
}

//MY LOCATION HOVER
.svg-hover .my-location {
  path {
    transition: transform 0.3s;
    transform-origin: 50%;
  }
  &:hover,
  .icon-hover-true & {
    animation: locPop 0.8s ease forwards;
    path:nth-child(1) {
      transform: scale(1.2);
    }
    path:nth-child(2) {
      transform: scale(0.7);
    }
  }
}
@keyframes locPop {
  0%,
  20%,
  60%,
  100% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.8);
  }
  80% {
    transform: scale(0.9);
  }
}

//SOUND HOVER
.svg-hover .sound {
  path:nth-child(1),
  path:nth-child(2),
  path:nth-child(3),
  path:nth-child(4) {
    stroke-dasharray: 20;
    stroke-dashoffset: 40;
  }
  &:hover,
  .icon-hover-true & {
    path:nth-child(1) {
      animation: soundon 0.8s ease 0.2s forwards;
    }
    path:nth-child(2) {
      animation: soundon 0.8s ease 0.2s forwards;
    }
    path:nth-child(3) {
      animation: soundon 0.8s ease 0.1s forwards;
    }
    path:nth-child(4) {
      animation: soundon 0.8s ease 0s forwards;
    }
  }
  .loop & {
    path:nth-child(1) {
      animation: soundon 0.8s ease 0.2s infinite;
    }
    path:nth-child(2) {
      animation: soundon 0.9s ease 0.2s infinite;
    }
    path:nth-child(3) {
      animation: soundon 0.8s ease 0.1s infinite;
    }
    path:nth-child(4) {
      animation: soundon 0.7s ease 0s infinite;
    }
  }
}
@keyframes soundon {
  0% {
    stroke-dashoffset: 40;
  }
  50% {
    stroke-dashoffset: 28;
  }
  100% {
    stroke-dashoffset: 40;
  }
}

.reverse {
  path,
  rect,
  line,
  circle,
  polygon {
    transition: stroke 0.4s;
    stroke: #fff;
  }
}
.icon-hover-true .reverse {
  path,
  rect,
  line,
  circle,
  polygon {
    stroke: #000;
  }
}
