@import '../sass/abstracts/vars';

.sitemap-navigation {
  ul {
    list-style: none;
  }

  li {
    a {
      font-family: Inter;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
    }
  }

  ul ul {
    margin-left: 1rem
  }

}