@layer components {
  img {
    max-width: unset;
  }
  .adf-button {
    @apply relative flex max-w-[200px] hover:no-underline;

    &.action {
      > .button-fill {
        @apply absolute bottom-0 left-0 right-0 top-0 z-0 origin-left scale-x-0 transition duration-500;
      }
      > .button-icon {
        @apply z-[1] bg-none;
      }
      &:hover {
        &.tri-service {
          > .button-fill {
            @apply scale-x-0 bg-tri-service;
          }
        }
        &.army {
          > .button-fill {
            @apply scale-x-0 bg-army;
          }
        }
        &.navy {
          > .button-fill {
            @apply scale-x-0 bg-navy;
          }
        }
        &.air-force {
          > .button-fill {
            @apply scale-x-0 bg-air-force;
          }
        }
      }
    }

    &.secondary,
    &.action {
      .button-text {
        height: 60px;
      }

      &.tri-service {
        @apply hover:border-tri-service;

        &:hover {
          .button-icon {
            // @apply bg-tri-service border-tri-service;
            .button-fill {
              @apply border-tri-service bg-tri-service;
            }
          }
        }
      }

      &.army {
        @apply hover:border-army;

        &:hover {
          .button-icon {
            // @apply bg-army border-army;
            .button-fill {
              @apply border-army bg-army;
            }
          }
        }
      }

      &.navy {
        @apply hover:border-navy;

        &:hover {
          .button-icon {
            // @apply bg-navy border-navy;
            .button-fill {
              @apply border-navy bg-navy;
            }
          }
        }
      }

      &.air-force {
        @apply hover:border-air-force;

        &:hover {
          .button-icon {
            // @apply bg-air-force border-air-force;
            .button-fill {
              @apply border-air-force bg-air-force;
            }
          }
        }
      }
    }

    &.primary {
      &.tri-service {
        .button-text {
          @apply border-tri-service;
        }
        .button-icon {
          @apply border-tri-service bg-tri-service;
        }

        &:hover {
          .button-icon {
            @apply border-tri-service bg-tri-service;
          }
        }
      }

      &.army {
        .button-text {
          @apply border-army;
        }
        .button-icon {
          @apply border-army bg-army;
        }
      }

      &.navy {
        .button-text {
          @apply border-navy;
        }
        .button-icon {
          @apply border-navy bg-navy;
        }
      }

      &.air-force {
        .button-text {
          @apply border-air-force;
        }
        .button-icon {
          @apply border-air-force bg-air-force;
        }
      }

      // &.disabled {
      //   pointer-events: none;
      //   .button-text, .button-icon, .button-fill {
      //     @apply bg-grey-light border-grey-light;
      //     span, svg {
      //       @apply opacity-60;
      //     }
      //   }
      // }
    }

    &.small {
      .button-text {
        @apply h-11;
        padding: 13px 15px;
      }

      &.secondary {
        .button-text {
          @apply h-10;
        }
      }

      &.primary,
      &.secondary {
        .button-icon {
          padding: 11px 14.5px;
        }
      }

      &.action {
        .left-icon {
          padding: 13.5px 8px 14.5px 14px;
        }

        .button-text {
          padding: 15px 16px 15px 0px;
        }
      }
    }

    .button-text {
      @apply h-16 rounded-[0.5px];
      @apply button flex max-w-[300px] items-center border uppercase text-black;
      @apply overflow-hidden text-ellipsis border-solid;
      padding: 25px 20px;
      span {
        @apply z-[1] overflow-hidden text-ellipsis;
      }
      @apply relative;
    }

    &.primary {
      .button-text .button-fill {
        @apply absolute bottom-0 left-0 right-0 top-0 z-0 origin-right scale-x-100 transition duration-500;
      }
      .button-icon {
        @apply flex items-center justify-center;
        padding: 20px; // 24.5px 20px;
      }
      &.tri-service:not(.disabled) {
        .button-text .button-fill {
          @apply bg-tri-service;
        }
      }
      &.army:not(.disabled) {
        .button-text .button-fill {
          @apply bg-army;
        }
      }
      &.navy:not(.disabled) {
        .button-text .button-fill {
          @apply bg-navy;
        }
      }
      &.air-force:not(.disabled) {
        .button-text .button-fill {
          @apply bg-air-force;
        }
      }

      &.disabled {
        pointer-events: none;
        .button-text,
        .button-icon,
        .button-fill {
          @apply border-grey-light bg-grey-light;
          span,
          svg {
            @apply opacity-60;
          }
        }
      }
    }

    &.primary {
      &:hover {
        // .button-text {
        //   @apply bg-white;
        // }
        .button-fill {
          @apply scale-x-0;
        }
      }
    }

    &.secondary {
      @apply border border-solid border-black transition duration-500;
      // &.disabled {
      //   @apply border-grey-medium;
      //   pointer-events: none;
      //   .button-text, .button-icon {
      //     @apply border-grey-medium;
      //     span, svg {
      //       @apply opacity-60;
      //     }
      //   }
      // }
      .button-text {
        @apply border-0;
      }
      .button-icon {
        @apply relative flex items-center justify-center;
        padding: 20px; // 22.5px 20px;
        div,
        svg {
          @apply z-[1];
        }
      }
      .button-icon .button-fill {
        @apply absolute bottom-0 left-0 right-0 top-0 z-0 origin-left scale-x-0 bg-white transition duration-500;
      }
      &:hover {
        .button-fill {
          @apply scale-x-100;
        }
      }
    }

    &.action {
      @apply border border-solid border-black;

      .left-icon {
        @apply flex justify-center;
        padding: 21.5px 8px 22.5px 20px;
      }

      .button-text {
        @apply border-0 bg-white;
        padding: 25px 22px 25px 0px;
      }
    }

    &.disabled {
      @apply border-grey-medium;
      pointer-events: none;
      .button-text,
      .button-icon {
        @apply border-grey-medium;
        span,
        svg {
          @apply opacity-60;
        }
      }
    }
  }

  .adf-button-tertiary {
    // @apply flex hover:no-underline border-solid border-b-[1.5px];
    // @apply hover:border-solid hover:border-black hover:border-b-[1.5px] pb-[2.5px];
    @apply flex flex-wrap hover:no-underline;
    @apply pb-[2.5px];
    border-color: transparent;

    .button-text {
      @apply rounded-[0.5px];
      @apply button items-center uppercase text-black;
      padding: 0.5px 0px 0.5px 0px;
    }

    .button-line {
      @apply mt-1 h-[1.5px] w-full origin-left scale-x-0 bg-black transition duration-500;
    }

    .button-icon {
      @apply pl-2;
    }

    .left-icon {
      @apply pb-px pl-0 pr-1.5;
    }

    &:hover {
      .button-line {
        @apply scale-x-100;
      }
    }
    &.disabled {
      pointer-events: none;
      .button-text,
      .button-icon {
        @apply text-grey-medium;
        span,
        svg {
          @apply opacity-60;
        }
      }
    }
  }

  .adf-button-icon {
    display: flex;
    gap: 4px;

    &.disabled {
      pointer-events: none;
      @apply bg-grey-light;
      @apply border-grey-light;
      .button-icon {
        @apply text-grey-medium;
        span,
        svg {
          @apply opacity-60;
        }
      }
    }

    .indicator {
      border-radius: 50%;
    }
  }

  .adf-button-profile {
    &:hover {
      text-decoration: none;
    }

    .profile-image {
      border-radius: 50%;
      height: 48px;

      @media only screen and (max-width: 480px) {
        height: 32px;
        width: 32px;
      }
    }
  }

  .adf-button-toggle {
    .outer-ellipsis {
      border-radius: 4000px;
    }
    .inner-ellipsis {
      border-radius: 50%;
    }

    &.enabled {
      .outer-ellipsis {
        background-color: $tri-service-black;

        .inner-ellipsis {
          transform: translateX(16px);
        }
      }
    }
  }

  .expand-bar {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);

    &.expanded {
      background: transparent;
    }

    .expand-bar-button {
      padding: 32px 0px 16px 24px;
    }
  }
}
