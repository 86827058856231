@font-face {
    font-family: 'uniform-condensed';
    font-weight: 600;
    font-display: swap;
    src: url("/fonts/Uniform-Condensed-Bold.woff2") format("woff2"),url("/fonts/Uniform-Condensed-Bold.woff") format("woff");
}

@font-face {
    font-family: 'uniform-condensed';
    font-weight: 500;
    font-display: swap;
    src: url("/fonts/Uniform-Condensed-Medium.woff2") format("woff2"),url("/fonts/Uniform-Condensed-Medium.woff") format("woff");
}

@font-face {
    font-family: 'uniform-condensed';
    font-weight: 400;
    font-display: swap;
    src: url("/fonts/Uniform-Condensed-Regular.woff2") format("woff2"),url("/fonts/Uniform-Condensed-Regular.woff") format("woff");
}

@font-face {
    font-family: 'uniform-condensed-light';
    font-weight: 400;
    font-display: swap;
    src: url("/fonts/Uniform-Condensed-Light-Regular.woff2") format("woff2"),url("/fonts/Uniform-Condensed-Light-Regular.woff") format("woff");
}

@font-face {
    font-family: 'inter';
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/Inter-Regular.ttf');
}

@font-face {
    font-family: 'inter';
    font-weight: 500;
    font-display: swap;
    src: url('/fonts/Inter-Medium.ttf');
}

@font-face {
    font-family: 'inter';
    font-weight: 600;
    font-display: swap;
    src: url('/fonts/Inter-SemiBold.ttf');
}
