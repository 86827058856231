@keyframes slideFromTopDown {
  0% {
    top: 0px;
  }
  100% {
    top: 100px;
  }
}

.adf-notification-container {
  position: absolute;
  top: 100px;
  width: 100%;
  animation: 1s ease-out 0s 1 slideFromTopDown;
  z-index: 99;

  // .editing-mode & {
  //   top: 200px;
  // }
}

.adf-notification {
  &.toast-notification {
    position: relative;
  }

  &.fade {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.5s, opacity 0.5s linear;
  }

  &.no-display {
    display: none;
  }
}

