// //website colors
// //Primary colors
// $tri-service-color: #F29129;
// $army-service-color: #F1B700;
// $air-force-service-color: #5797C6;
// $navy-service-color: #1E74F7;

// //primary color tints
// $tri-service-tint-color: #FDEFDF;
// $army-service-tint-color: #FDF5DF;
// $air-force-service-tint-color: #E6EFF6;
// $navy-service-tint-color: #E8F1FA;

// //alert colors and tints
// $error-red: #DB5A42;
// $success-green: #69AD4A;

// $error-red-tint: #F6D6D0;
// $success-green-tint: #DAEBD2;

// //Neutral colors
$tri-service-black: #000000;
// $tri-service-off-black: #181818;
// $tri-service-off-black-secondary: #272727;
$tri-service-dark-grey: #333333;
// $tri-service-medium-grey: #595959;
$tri-service-grey: #959595; // Was this removed?
$tri-service-light-grey: #D8D8D8;
$tri-service-pale-grey: #efefef;
// $tri-service-off-white: #F8F8F8;
$tri-service-white: #ffffff;
