@import 'tailwindcss/screens';

:root {
  --slide-cols: 1;
  @screen sm {
    --slide-cols: 1;
  }
  @screen md {
    --slide-cols: 2;
  }
  @screen lg {
    --slide-cols: 3;
  }
  // @screen xl {
  //   --slide-cols: 4;
  // }
  // @screen xxl {
  //   --slide-cols: 4;
  // }
}

.four-up {
  @screen xl {
    --slide-cols: 4;
  }
}

$page-container-width: calc(100vw - var(--scrollbar-width) - (var(--outermargin) * 2) + var(--gutter));
$slide-width: calc($page-container-width / var(--slide-cols) - var(--gutter));

.container-break-right > .carousel-swiper.swiper {
  margin-left: calc(var(--gutter) / 2);
  .swiper-slide {
    width: $slide-width !important;
    &:last-of-type {
      width: calc($slide-width + var(--outermargin)) !important;
      padding-right: var(--outermargin) !important;
    }
  }
}
