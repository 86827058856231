@layer base {
  h1,
  .h1 {
    @apply font-uniform-condensed text-h1 uppercase text-black antialiased dark:text-white [.dark_.adf-drawer_&]:text-black;
  }
  h2,
  .h2 {
    @apply font-uniform-condensed text-h2 uppercase text-black antialiased dark:text-white [.dark_.adf-drawer_&]:text-black;
  }
  h3,
  .h3 {
    @apply font-uniform-condensed text-h3 uppercase text-black antialiased dark:text-white [.dark_.adf-drawer_&]:text-black;
  }
  h4,
  .h4 {
    @apply font-uniform-condensed text-h4 text-black antialiased dark:text-white [.dark_.adf-drawer_&]:text-black;
  }
  h5,
  .h5 {
    @apply font-inter text-h5 text-black antialiased dark:text-white [.dark_.adf-drawer_&]:text-black;
  }
  h6,
  .h6 {
    @apply font-inter text-h6 text-black antialiased dark:text-white [.dark_.adf-drawer_&]:text-black;
  }
  .pull-quote-light {
    @apply font-uniform-condensed-light text-quote text-black antialiased dark:text-white [.dark_.adf-drawer_&]:text-black;
  }
  .pull-quote {
    @apply font-uniform-condensed text-quote text-black antialiased dark:text-white [.dark_.adf-drawer_&]:text-black;
  }
  .xlarge {
    @apply font-uniform-condensed text-xl antialiased;
  }
  .large {
    @apply font-uniform-condensed text-lg antialiased;
  }
  .medium {
    @apply font-uniform-condensed text-md antialiased;
  }
  .meta {
    @apply font-uniform-condensed text-meta font-medium antialiased;
  }
  .lbody {
    @apply font-inter text-lbody antialiased;
  }
  .lbody-bold {
    @apply font-inter text-lbody font-semibold antialiased;
  }
  .mbody {
    @apply font-inter text-mbody antialiased;
  }
  .mbody-bold {
    @apply font-inter text-mbody font-semibold antialiased;
  }
  .sbody {
    @apply font-inter text-sbody antialiased;
  }
  .sbody-bold {
    @apply font-inter text-sbody font-semibold antialiased;
  }
  .xsbody {
    @apply font-inter text-xsbody antialiased;
  }
  .xsbody-bold {
    @apply font-inter text-xsbody font-semibold antialiased;
  }
  .mnav {
    @apply font-uniform-condensed text-mnav font-medium uppercase antialiased;
  }
  .snav {
    @apply font-inter text-snav font-medium antialiased;
  }
  .ltag {
    @apply font-uniform-condensed text-ltag font-semibold uppercase antialiased;
  }
  .ltagclamp {
    @apply font-uniform-condensed text-ltagclamp font-semibold uppercase antialiased;
  }
  .mtag {
    @apply font-uniform-condensed text-mtag font-semibold uppercase antialiased;
  }
  .stag {
    @apply font-uniform-condensed text-stag font-medium uppercase antialiased;
  }
  .xstag {
    @apply font-uniform-condensed text-xstag font-medium uppercase antialiased;
  }
  .xstag-bold {
    @apply font-uniform-condensed text-xstag font-semibold uppercase antialiased;
  }
  .button {
    @apply font-uniform-condensed text-button font-semibold uppercase antialiased;
  }

  // RICH TEXT and DEFAULT LIST STYLES

  .component.rich-text {
    // add spacing between all blocks inside rich text editor
    .component-content > div {
      @apply flex flex-col gap-6;
    }
    a:not(.button-adf) {
      @apply link-underline;
    }

    ul,
    ol {
      > li {
        @apply relative mt-4 block border-b border-grey-light pb-4 pl-8;
        &:before {
          @apply mr-3 flex shrink-0 items-center justify-center rounded-full;
          position: absolute;
          display: flex;
          left: 0;
          width: 1.7em;
          height: 1.7em;
          line-height: 1.7em;
          font-size: 0.725em;
          border: 1px solid currentColor; // transform: translateY(-50%);
          padding-top: 1px;
          top: 0.6lh;
          max-width: 1.25rem;
          max-height: 1.25rem;
          transform: translateY(-50%);
        }
        ul,
        ol {
          @apply mt-4 border-t border-grey-light;
          > li:last-of-type {
            @apply border-0 pb-0;
          }
        }
      }
    }

    // I feel this mixin should go somewhere else, but the SASS structure is a bit of a mess.
    @mixin marker-color($color) {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' stroke='#{$color}' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.5 7L9.5 17L4.5 12' stroke-width='1.4' stroke-linecap='square' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }

    ul {
      > li {
        font-size: 1rem;
        line-height: 1.5;
        &:before {
          @apply bg-center bg-no-repeat;
          background-size: 80%;
          content: '';
          body.light & {
            // use urlencoded hex color code
            @include marker-color('%23595959');
          }
          body:not(.light) & {
            @include marker-color(white);
          }
        }
      }
    }
    ol {
      counter-reset: ol-counter;
      > li {
        &:before {
          @apply font-semibold;
          content: counter(ol-counter);
          counter-increment: ol-counter;
          text-align: center;
        }
        > ol {
          > li {
            display: list-item;
            &:before {
              content: counter(ol-counter, lower-alpha);
            }
          }
        }
      }
    }
    dt,
    summary {
      @apply font-semibold;
    }
  }
}

@layer utilities {
  .link-underline {
    @apply bg-gradient-to-r from-current to-current box-decoration-slice bg-[length:100%_1px] bg-bottom bg-no-repeat text-black no-underline transition-[background] duration-500 ease-in-out hover:bg-[length:0%_1px];
  }
}
